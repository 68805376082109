import React from "react";
import { useState } from "react";
import ReactMapGL from "react-map-gl";

const Initiatives = () => {
  return (
   <h1>Initatives</h1>
  );
};

export default Initiatives;